<template>
    <modal ref="modalBuscarEtiquetas" titulo="Buscar" noAceptar adicional="Aceptar" @adicional="guardarUsuarios" :desactivarGuardar="false">
        <div class="col-12  my-4">
            <el-select
            v-model="seleccionado"
            filterable size="small"
            class="col-12 p-0"
            @change="agregarPorEtiqueta"
            >
                <el-option
                v-for="(item) in etiquetas"
                :key="item"
                :value="item"
                :label="item"
                class="my-2"
                />
            </el-select>
            <div class="col-12 p-0 my-3">
                <div class="col-12 mx-auto py-2 mb-3 d-flex align-items-center bg-whitesmoke br-10">
                    <div class="mr-1 py-2">
                        <el-checkbox
                        v-model="seleccionarTodos"
                        />
                    </div>
                    <p class="f-16 ml-1">Seleccionar todos</p>
                </div>
                <div class="col-12 px-4">
                    <el-checkbox-group
                    v-model="usuarios_seleccionados"
                    class="d-flex flex-column"
                    >
                        <el-checkbox
                        v-for="(item) in usuarios"
                        :key="item.id"
                        :value="item.id"
                        :label="item.nombre"
                        class="my-2"
                        />
                    </el-checkbox-group>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'

export default {
    data(){
        return{
            seleccionado : null,
            seleccionarTodos: false, 
            usuarios:[],
            user:[],
            usuarios_seleccionados:[],  
            etiquetas:[],
            usuariosSeleccionados:[],

        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    watch:{
        seleccionarTodos(val){
            if(!this.seleccionarTodos){
                this.usuariosSeleccionados = []
            }
            if(val){
                this.usuarios_seleccionados = this.usuarios.map(el => {
                    if(this.seleccionarTodos){
                        this.usuariosSeleccionados.push(el.id)
                    }
                    return el.nombre})

            }else{
                this.usuarios_seleccionados = []
            }


        }
    },
    mounted(){
        this.selectUsuarios()

    },
    methods:{
        async guardarUsuarios(){
            try {
                let model = this.usuariosSeleccionados
                const {data} = await Cursos.guardarLectores(this.id_curso, model) 
                this.toggle()
            } catch (error){
                this.error_catch(error)
            }
        },
        async selectUsuarios(){
            try {
                const {data} = await Cursos.selectUsuarios(this.id_curso,1) // tipo 1 => lector
                this.usuarios = data.data.usuarios
                this.user = this.usuarios
                this.listaEtiquetas()

            } catch (error){
                this.error_catch(error)
            }
        },
        
        listaEtiquetas(){
            try {
                this.etiquetas = this.usuarios.map(function(item){
                    return item.cargo
                })
                this.etiquetas = this.etiquetas.filter((item,index)=>{
                    return this.etiquetas.indexOf(item) === index;
                })
            } catch (error){
                console.log(error)
                this.error_catch(error)
            }
        },


        agregarPorEtiqueta(){
            this.usuarios = this.user
            this.usuarios = this.usuarios.filter((item)=>{
                if(item.cargo == this.seleccionado){
                    return item
                }
            })
        },
        toggle(){
            this.$refs.modalBuscarEtiquetas.toggle()
        },

    }
}
</script>